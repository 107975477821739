import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container py-4">
          <div className="row justify-content-between align-items-center mb-lg-5 mb-4">
            <div className="col-md-2">
              <div className="footer-logo">
              <Link to="/">
            <img src="assets/logo.svg" height={30} alt="" />
          </Link>
              </div>
            </div>
            <div className="col-md-5">
              <div className="d-md-flex d-none gap-4 justify-content-md-end justify-content-center mb-md-0 mb-3">
                <a href="https://discord.gg/zkswap" target={"_blank"} className='footer-link'><img src="assets/Discord.png" alt="" /></a>
                <a href="https://twitter.com/_zkSwap" target={"_blank"} className='footer-link'><img src="assets/twitter.png" alt="" /></a>
                <a href="https://medium.com/@_zkSwap" target={"_blank"} className='footer-link'><img src="assets/medium.png" alt="" /></a>
              </div>
            </div>
          </div>
          <div className="row justify-content-between align-items-center">
            <div className="col-md-7">
              <div className="d-flex flex-wrap gap-4 justify-content-md-start mb-md-0 mb-5">
                <a href="https://docs.zk-swap.xyz/overview/introduction" target={"_blank"} className='footer-link sm-50'>Docs</a>
                <a href="https://medium.com/@_zkSwap" target={"_blank"} className='footer-link sm-50'>Blog</a>
                <a href="https://app.zk-swap.xyz/analytics" target={"_blank"} className='footer-link sm-50'>Dashboard</a>
                <a href="https://www.coingecko.com/en/coins/zkswap#markets" target={"_blank"} className='footer-link sm-50'>CoinGecko</a>
                <a href="https://terms.zk-swap.xyz/terms-conditions" target={"_blank"} className='footer-link sm-50'>Terms of Service</a>
                <a href="https://terms.zk-swap.xyz/privacy-policy" target={"_blank"} className='footer-link sm-50'>Privacy Policy</a>
                <a href="https://drive.google.com/drive/folders/1B_bw-BND1kx9Iovihb6FzDRIuweoH97w?usp=sharing" target={"_blank"} className='footer-link sm-50'>Brand Assets</a>

              </div>
              <div className="d-flex d-md-none gap-4 justify-content-md-end justify-content-center mb-md-0 mb-3">
              <a href="https://discord.gg/zkswap" target={"_blank"} className='footer-link'><img src="assets/Discord.png" alt="" /></a>
                <a href="https://twitter.com/_zkSwap" target={"_blank"} className='footer-link'><img src="assets/twitter.png" alt="" /></a>
                <a href="https://medium.com/@_zkSwap" target={"_blank"} className='footer-link'><img src="assets/medium.png" alt="" /></a>
              </div>
            </div>
            <div className="col-md-3">
            <div className="d-flex gap-md-5 gap-4 justify-content-md-end justify-content-center mb-md-0 mb-3">
            <p className='footer-link'>Copyright © 2023 zkSwap</p>
            </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer