import React, { useEffect, useState } from "react";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
const Home = () => {
  const renderTooltip = (address:any) => (
    <Tooltip style={{
      borderRadius: "6px",
      border: "1px solid #0090BF",
      padding: "2px 8px",
      fontSize: "13px",
      lineHeight: 1.2,
      background: "linear-gradient(45deg, rgba(85, 0, 255, 0.30) 0%, rgba(85, 0, 255, 0.01) 100%), linear-gradient(0deg, rgba(0, 144, 191, 0.30) 0%, rgba(0, 144, 191, 0.30) 100%), #48484D",
      backgroundBlendMode: "color, normal, normal",
      color: 'white',
    }}>
      <div className="bg-transparent">{address}</div>
    </Tooltip>
    
  );

  const [data, setData] = useState<any>();


  function convertToMillion(value: number) {
    return (value / 1000000).toFixed(2) + 'M';
  }

  function convert(value: number) {
    return (value?.toFixed(2) )
  }


  useEffect(() => {
    axios.get('https://api.zk-swap.xyz/token_stats')
      .then(res => {
        setData(res.data);
        // console.error(res.data);
      })
      .catch(err => { 
        console.error(err);
      });
  }, []);




  return (
    <>
      {/* Banner  */}
      <section className="banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-10 z-1">
            <div className="primary-badge mb-4">Built on <img src="assets/Bimg.svg" className="ms-2" alt="" /></div>
              <h1>
              Concentrated Liquidity DEX on zkSync
              </h1>
              <p className="my-5">
              Enhancing capital efficiency and flexibility<br className="d-lg-block d-none" /> for traders and liquidity providers
              </p>
              <div className="d-flex gap-4">
              <a href="https://app.zk-swap.xyz/" target={"_blank"} className="primary-btn">
                  Launch app
                </a>
                <a href="https://docs.zk-swap.xyz/overview/introduction" target={"_blank"} className="primary-btn-outline">
                  <span>REad Docs</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="py-5 my-lg-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h2 className="mb-lg-5 mb-4">Protocol Stats</h2>

            <div className="card-grid">
              <div className="feature-card">
                <div className="feature-img"><img src="assets/pr1.svg" alt="" /></div>
                <p>FDV</p>
                <h4>${convertToMillion(data?.fdv)}</h4>
              </div>

              {/* <div className="feature-card">
                <div className="feature-img"><img src="assets/pr2.svg" alt="" /></div>
                <p>Price</p>
                <h4>${convert(data?.price_usd)}</h4>
              </div> */}

              <div className="feature-card">
                <div className="feature-img"><img src="assets/pr3.svg" alt="" /></div>
                <p>Ticker</p>
                <h4>$ZKSP</h4>
              </div>

              <div className="feature-card">
                <div className="feature-img"><img src="assets/pr4.svg" alt="" /></div>
                <p>Twitter Followers</p>
                <h4>39k</h4>
              </div>

              <div className="feature-card">
                <div className="feature-img"><img src="assets/pr5.png" alt="" /></div>
                <p>Discord</p>
                <h4>33k</h4>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      </section>     
      <section className="py-5 my-lg-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h2 className="mb-lg-5 mb-4">Unleash the Power of zkSwap</h2>

            <div className="row">
              <div className="col-md-4 mb-md-0 mb-4">
              <div className="feature-card">
                <div className="feature-img"><img src="assets/u1.svg" alt="" /></div>
                <h4>Low Cost</h4>
                <p>Trade more, pay less. zkSwap's use of zkSync's rollups means lower gas fees.</p>
              </div>
              </div>

              <div className="col-md-4 mb-md-0 mb-4">
              <div className="feature-card">
                <div className="feature-img"><img src="assets/u2.svg" alt="" /></div>
                <h4>Low Slippage</h4>
                <p>Minimize slippage with our concentrated liquidity model. zkSwap gives you the best value.</p>
              </div>
              </div>

              <div className="col-md-4 mb-md-0 mb-4">
              <div className="feature-card">
                <div className="feature-img"><img src="assets/u3.svg" alt="" /></div>
                <h4>Maximum Capital Efficiency</h4>
                <p>Take control with zkSwap. Optimize your asset allocation and supercharge your capital.</p>
              </div>
              </div>

            </div>
          </div>
        </div>
       
      </div>
      </section>
      

      <section className="how-it-work">
        <div className="container">
          <div className="row justify-content-center align-items-center py-4 py-lg-5">
            <div className="col-md-5 order-md-0 order-1">
              <div className="primary-badge mb-4 d-md-inline-block d-none">For Traders</div>
              <h2>Swap Tokens</h2>
              <p>Elevate your trading game with zkSwap, thanks to our concentrated liquidity around current market prices.</p>
            </div>
            <div className="col-md-5">
            <div className="primary-badge mb-4 d-md-none d-inline-block">For Traders</div>
            <img src="assets/h1.png" className="w-100" alt="" />
            </div>
          </div>

          <div className="row justify-content-center align-items-center py-4 py-lg-5">
          <div className="col-md-5">
          <div className="primary-badge mb-4 d-md-none d-inline-block">For Liquidity Providers</div>
            <img src="assets/h2.png" className="w-100" alt="" />
            </div>
            <div className="col-md-5">
            <div className="primary-badge mb-4 d-md-inline-block d-none">For Liquidity Providers</div>
              <h2>Manage Liquidity</h2>
              <p>Boost efficiency as a Liquidity Provider. Define your own profitable price range.</p>
            </div>
            
          </div>

          <div className="row justify-content-center align-items-center py-4 py-lg-5">
            <div className="col-md-5 order-md-0 order-1">
            <div className="primary-badge mb-4 d-md-inline-block d-none">For Yield hunters</div>
              <h2>Earn Yield</h2>
              <p>Put your liquidity to work on zkSwap Farms. Stake now, start earning instantly!</p>
            </div>
            <div className="col-md-5">
            <div className="primary-badge mb-4 d-md-none d-inline-block">For Yield hunters</div>
            <img src="assets/h3.png" className="w-100" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 my-lg-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center mb-lg-5 mb-4">Partners</h2>
            </div>

            <div className="col-md-12">
              <div className="p-grid">
              <div className="partner-img">
                  <img src="assets/p0.png" className="img12" alt="" />
                </div>
                <div className="partner-img">
                  <img src="assets/p1.png" className="img12"  alt="" />
                </div>
                <div className="partner-img">
                  <img src="assets/p2.png" className="img1 img12" alt="" />
                </div>
                <div className="partner-img">
                  <img src="assets/p3.png" className="img12" alt="" />
                </div>
                <div className="partner-img">
                  <img src="assets/p4.png" alt="" />
                </div>
                <div className="partner-img">
                  <img src="assets/p5.png" className="img12" alt="" />
                </div>
                <div className="partner-img">
                  <img src="assets/p6.png" alt="" />
                </div>
                <div className="partner-img">
                  <img src="assets/p7.png" className="img12" alt="" />
                </div>
                <div className="partner-img">
                  <img src="assets/p8.png" className="img12" alt="" />
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="py-5 my-lg-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <h2 className="text-center mb-lg-5 mb-4">Audited by</h2>
            </div>

            <div className="col-md-2 col-9">
              <div className="partner-img">
                  <img src="assets/solid.png" className="img12" alt="" />
                </div>                
            </div>
          </div>
        </div>
      </section>


      <section className="av-bg">
        <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 text-center mb-lg-5 mb-4">
              <h2 className="mb-3">Contributors</h2>
              <p>We would like to thank our awesome group of contributors for allowing us to make zkSwap DAO what it is today.</p>
            </div>
            </div>
          <div className="row justify-content-center">
         
            <div className="col-md-5">
              <div className="av-grid">
              <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0x5d71d02f4483D5A924AdCa292F17CF2a008a6731")}>
              <a href="javascript:void(0)" className="avatar"><img src="assets/av/av1.svg" alt="" /></a>
              </OverlayTrigger>                
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0x288FCBd8f138DA95782A48a2526a1E8F99060263")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av2.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xD7d4AA548f2a43F330f89D95f247d958eb889ec2")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av3.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0x937e1b6fe9080AbcD01A7bBa50e9B516Ec731532")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av4.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xa966dba54bb0dcbfcd830395994567c0587e3b3e")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av5.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0x64164455646b931baB3a4936f2aCc29f73fA61B9")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av6.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xA89E0B7673769472f59e5a0E605745fA5a92432C")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av7.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xB4759a72aF66Fa9E0c1EA87e2Dc51d9e468A3C5c")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av8.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xe1DE6575edc4F48489F582936F150C5EB0a482F0")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av9.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0x7406483069097A1055F8077d0f840A224D12C8E0")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av10.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xc5135d008c1fb7B4554255eC4d82cDB37DDD8A09")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av11.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0x40517c8678Dc83BC2E8E0B71AD0C0dE7d58a2BF9")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av12.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xBB5905325Ea3Ebd3f2EcFe12E84B1b74C1CdBB1a")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av13.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xC2875CD21E1fc1e4eC9a967f41a20B298FD39485")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av4.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xC956793E5A7CBE9e65348d1520E68eBE8286046C")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av5.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xFE1d497D959605A986C114f2d38d71c07dc9e289")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av6.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0x85039bde950b8A569BFaC747EcA531028FfE1E9b")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av7.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xF2e672429B5c18e156765d1Fc3DE9215c79320bF")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av8.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xE475A6dEC3d44d0B7A6608A9Cea292C8e8CBaba4")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av9.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0x8Fe17ed96010e123Ae9e33178A69FB1F3a20789d")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av10.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xa65aF041B73F6ed0A13C907B8d24e3E05225E939")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av7.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xf7869f719C5e1C0b6B7059f4cab4A149CC91fF3D")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av8.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0x5477d4639E998Ddda7E97F44aD159f4b7f1E3fc7")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av9.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xeb8424A77105A63af7212e540f17767dd39C9F96")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av10.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xAfA3a2528E8baAd576a83ffC52dB9f100dEbe307")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av11.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0x0859E0C3faf717d34e5B26D3E1854f0716f3F790")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av12.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0x7989689E8BAE9874793Ba34dCFfb2AC52573C464")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av13.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0x95Ff996558a173ba43b24f02B2F4BeE56D5bae1B")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av4.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xd546A8e96af21dB681F7CA1A593c673d64f0acE5")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av5.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0x4024c1500bE2c65d0bC1b53c75D4Cf0f06Be6F88")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av6.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xE33A5f352ff0aDe0a2bC14C1985f790Cc38864b1")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av13.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0x75Fa320b0AAbAbbA1f090BF18a7E64271b0D3aDa")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av4.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xCB06159Cf19eeaD28657860a58d392f1991DAb23")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av5.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0x4450dB99260EC3140B4690F1c6bDd64d5d9644A9")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av6.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0xC928a7B41edA43ddE3c8D74c3eB148A45B09029E")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av7.svg" alt="" /></a>
                  </OverlayTrigger>
                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip("0x434a8637c93868106EE2B633cF524e02Bf63a037")}>
                  <a href="javascript:void(0)" className="avatar"><img src="assets/av/av8.svg" alt="" /></a>
                  </OverlayTrigger>

              </div>
            </div>
          </div>
        </div>
      </section>

   


    
      
    </>
  );
};

export default Home;
