import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

const Header = () => {
  const [stickyClass, setStickyClass] = useState('relative');
  const homeNav = () => {
    if (document.body.classList.contains("home-sm")) {
      document.body.classList.remove("home-sm");
    } else {
      document.body.classList.add("home-sm");
    }
  };

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 50 ? setStickyClass('bg-black') : setStickyClass('bg-transparent');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  return (
    <div>
      <nav className={`navbar navbar-expand-lg transition-all ${stickyClass}`}>
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src="assets/logo.svg" alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => {
              homeNav();
            }}
          >
            <img src="assets/burger.png" alt="" />
          </button>
          <div className="collapse navbar-collapse w-100 justify-content-end" id="navbarSupportedContent">
            <div className="d-md-none d-block text-end p-4">
              <a href="javascript:void(0)" onClick={() => {homeNav();}}  className="cross-btn text-white">
                <span className="iconify" data-icon="akar-icons:cross"></span>
              </a>
            </div>
            <ul className="navbar-nav align-items-center px-4 px-lg-0 me-lg-4 me-0">
              <li className="nav-item">
                <a href="https://izumi.finance/trade/swap" target={"_blank"} className="nav-link" onClick={() => {homeNav()}}>
                Buy $ZKSP
                </a>
              </li>
              <li className="nav-item">
                <a href="https://docs.zk-swap.xyz/overview/introduction" target={"_blank"} className="nav-link" onClick={() => {homeNav()}}>
                Docs
                </a>
              </li>
              <li className="nav-item">
                <a href="https://medium.com/@_zkSwap" target={"_blank"} className="nav-link" onClick={() => {homeNav()}}>
                Blog 
                </a>
              </li>
              
            </ul>
            <div className="d-lg-flex gap-3 px-4 px-lg-0 mt-lg-0 mt-4">
              <a href="https://app.zk-swap.xyz/" target={"_blank"} className="primary-btn d-inline-flex mt-lg-0 mt-4 h-40">
              Launch app
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
